<template>
  <section class="how-page parallax">
    <div class="pop-in left">
      <div class="content wide">
        <h2>Hoe gaan we te werk?</h2>
        <p>
          Verwarringen uit de wereld helpen is &eacute;&eacute;n van onze
          hoofddoelen.
        </p>
        <p>
          Vooraleer iemand in de war raakt, is er altijd een begrip dat hij of
          zij niet helemaal gesnapt heeft. Als een kind niet kan toepassen wat
          het geleerd heeft, is er ergens iets dat het niet begrepen heeft. Als
          iemand blijft fouten maken is er iets dat hij of zij niet helemaal
          begrepen heeft.
        </p>
        <p>
          Iemand raakt maar verward over een onderwerp als er begrippen in dat
          onderwerp zijn die hij of zij niet volledig begrijpt. Onze taak
          bestaat erin de bron van de moeilijkheid op te sporen. Om die bron te
          vinden, spoelen wij terug naar het moment waarop de problemen begonnen
          zijn en maken op basis daarvan een individueel programma.
        </p>
        <p>
          Als de persoon die eerdere stappen begrijpt, kan hij of zij ook de
          volgende stappen begrijpen. Daarnaast herstellen wij zoveel mogelijk
          het zelfvertrouwen van de leerling door te benadrukken wat hij goed
          gedaan heeft. Wij vertrekken van het principe: als je het goede
          benadrukt, krijg je meer goede dingen.
        </p>
      </div>
    </div>
    <!--<NextButton linkToID="activities" />-->
  </section>
</template>

<script>
import ParallaxPage from "./ParallaxPage.vue";
// import NextButton from './NextButton.vue'
export default {
  name: "HowPage",
  extends: ParallaxPage,
  components: {
    // NextButton,
  },
};
</script>

<style lang="scss" scoped>
.how-page {
  background-image: url("../../assets/images/background/how.jpg");
  display: flex;
  flex-direction: column;
}
</style>