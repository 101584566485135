<template>
  <section class="who-page parallax">
    <div class="pop-in right">
      <div class="content">
        <h2>Wie zijn we?</h2>
        <p>
          <strong>Creatief leren</strong> heeft zich tot doel gesteld kinderen
          en jongvolwassenen, die om het even welke moeilijkheden ondervinden
          bij het leren of die beter zouden willen studeren, te helpen.
        </p>
        <p>
          We leren hen een bruikbare en effici&euml;nte leermethode aan zodat ze
          weer graag leren en zodat ze begrijpen en kunnen toepassen wat ze
          geleerd hebben.
        </p>
        <p>
          Op die manier worden ze succesvol in hun studies en in hun latere
          leven.
        </p>
        <p>
          Bij ons zit u aan de bron. Wij werken niet met tussenpersonen dus
          hoeft u niet te betalen voor derden. De diensten worden rechtstreeks
          door ons geleverd.
        </p>
      </div>
    </div>
    <!--<NextButton linkToID="how" />-->
  </section>
</template>

<script>
import ParallaxPage from './ParallaxPage.vue'
// import NextButton from './NextButton.vue'
export default {
  name: "WhoPage",
  extends: ParallaxPage,
  components: {
    // NextButton,
  }
}
</script>

<style lang="scss" scoped>
.who-page {
  background-image: url('../../assets/images/background/who.jpg');
  display: flex;
  flex-direction: column;
}
</style>