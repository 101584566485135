<template>
  <section class="activities-page parallax">
    <div class="content">
      <h2>Activiteiten</h2>
      <div class="row">
        <!-- Bijlessen -->
        <ActivityInfo>
          <template #link>
            <img src="../../assets/images/activities/bijlessen.png" />
            <p><strong>Bijlessen</strong></p>
          </template>
          <template #info-image>
            <img
              class="info-image"
              src="../../assets/images/activities/bijlessen.png"
            />
          </template>
          <template #info-title> Bijlessen </template>
          <template #info-text>
            <p>
              De bijlessen worden individueel gegeven en er wordt zorg voor
              gedragen dat de leerling de stof echt begrijpt en kan toepassen.
              Vooraleer iets ingeoefend wordt, overtuigen wij er ons van dat de
              leerling de leerstof volledig begrijpt. Pas dan gaan we over op
              het inoefenen van de leerstof. Elke mogelijke verwarring wordt
              opgespoord en opgehelderd. Door de nadruk te leggen op de goede
              dingen worden de kinderen aangemoedigd en stijgt hun
              zelfvertrouwen. Hier hanteren we het eerder genoemde principe: als
              je de goede dingen benadrukt dan krijg je meer van die goede
              dingen.
            </p>
          </template>
        </ActivityInfo>

        <!-- taal -->
        <ActivityInfo>
          <template #link>
            <img src="../../assets/images/activities/taal.png" />
            <p><strong>Spelling en spraakkunst</strong></p>
          </template>
          <template #info-image>
            <img
              class="info-image"
              src="../../assets/images/activities/taal.png"
            />
          </template>
          <template #info-title>Spelling en spraakkunst</template>
          <template #info-text>
            <p>
              Heel wat kinderen, adolescenten en zelfs volwassenen kunnen niet
              meer zonder fouten schrijven of hebben geen kaas gegeten van de
              Nederlandse spraakkunst.
            </p>
            <p>
              Nochtans om een boodschap correct over te brengen of om een goede
              brief te kunnen opstellen, moet men op de hoogte zijn van
              zinsstructuren en spellingsregels. Zelfs als we spreken met iemand
              moeten we weten hoe onze zinnen op te bouwen zodat de andere ons
              begrijpt.
            </p>
            <p>
              Spellingsregels worden willekeurig aangeleerd op school. En
              woordsoorten en zinsdelen worden door elkaar gemengd tot een
              hutsepot. Nochtans is het belangrijk om een correcte zin te kunnen
              maken en om zonder fouten te kunnen schrijven.
            </p>
            <p>
              Onze taal is een communicatiemiddel en als we ons niet correct
              uitdrukken, worden we aan de andere kant van de communicatielijn
              verkeerd begrepen met alle gevolgen van dien.
            </p>
            <p>
              Kinderen en volwassenen die hun spelling en spraakkunst willen
              bijwerken kunnen hiervoor bij ons terecht. De regels worden
              systematisch bekeken en de hutsepot van zinsdelen en woordsoorten
              wordt uit elkaar gehaald.
            </p>
          </template>
        </ActivityInfo>
      </div>
      <div class="row">
        <!-- Lezen -->
        <ActivityInfo>
          <template #link>
            <img src="../../assets/images/activities/lezen.png" />
            <p><strong>Aanpak van dyslexie en dyscalculie</strong></p>
          </template>
          <template #info-image>
            <img
              class="info-image"
              src="../../assets/images/activities/lezen.png"
            />
          </template>
          <template #info-title>
            Aanpak van dyslexie en dyscalculie
          </template>
          <template #info-text>
            <p>
              Lezen is klanken aan elkaar rijgen tot woorden en zinnen en begrijpen wat die woorden en zinnen betekenen.
            </p>
            <p>
              Taal bestaat uit klanken en die klanken worden weergegeven  als symbolen.
              In het Nederlands hebben we 26 symbolen waarmee we alle klanken uit deze taal kunnen uitdrukken.
              Die symbolen noemen we de letters  van het  alfabet.
            </p>
            <p>
              Soms gaat  het herkennen van die letters niet zo goed en dan spreken we van leesmoeilijkheden.
              Ernstige leesmoeilijkheden worden dan woordblindheid of dyslexie genoemd.
            </p>
            <p>
              Het woord dyslexie is afkomstig uit het Grieks.
            </p>
            <p>
              <strong>Dyslexie</strong> [woordblindheid] {1926-1950} gevormd van <strong>Grieks</strong> <span class="accent">duslektos</span> [moeilijk te vertellen],
              van <span class="accent">dus-</span> [slecht, moeilijk] + <span class="accent">lexis</span> [het spreken], maar beïnvloed door <strong>Latijn</strong> <span class="accent">legere</span> [lezen].
            </p>
            <p>
              Vlot kunnen lezen heeft ook veel te maken met het begrijpen van de woorden die in de tekst staan.
              Als men voorbij woorden leest die men niet begrijpt,  zal men de volgende woorden niet correct lezen of zal men gewoon raden wat er zou kunnen staan. 
              Dit wordt gecreëerd doordat er aandachtsdeeltjes blijven hangen aan de woorden die niet begrepen zijn. De persoon is niet meer aandachtig aan het lezen.
            </p>
            <p>
              Woorden en symbolen, waar het kind geen definitie voor heeft, zijn de grootste struikelblokken bij lezen en begrijpend lezen.
              Als het kind alle woorden begrijpt die in een tekst staan dan zal het ook de inhoud van de tekst begrijpen
              en zal het vragen kunnen beantwoorden waarvan het antwoord in de tekst  terug te vinden is.
            </p>
            <p>
              Dit is ook van toepassing op <strong>dyscalculie</strong>. (leerstoornis bij het rekenen)
            </p>
            <p>
              De term &apos;dyscalculie&apos; is etymologisch afgeleid uit het Grieks en het Latijn. &apos;Dys&apos; ( moeilijk) en &apos;calculare&apos; ( rekenen).
            </p>
            <p>
              Wiskunde bevat veel begrippen die niet altijd begrepen worden en waardoor de persoon moeilijkheden ondervindt.
              Ook is het een erg abstracte bezigheid. Men werkt enkel met abstracte hoeveelheden en voor een kind is dit soms heel moeilijk.
              Het heeft niets om die hoeveelheden aan vast te hechten en dat heeft tot gevolg dat het kind bepaalde lichamelijke en mentale reacties ondervindt.
              Bij vraagstukken komt dan ook nog het begrijpen van de tekst om het hoekje kijken wat het extra moeilijk maakt.
            </p>
          </template>
        </ActivityInfo>

        <!-- studie management -->
        <ActivityInfo>
          <template #link>
            <img src="../../assets/images/activities/studiemanagement.png" />
            <p>
              <strong>Studiecoaching</strong>
            </p>
          </template>
          <template #info-image>
            <img
              class="info-image"
              src="../../assets/images/activities/studiemanagement.png"
            />
          </template>
          <template #info-title>
            Studiecoaching
          </template>
          <template #info-text>
            <p>
              Eenmaal in het secundair onderwijs hebben heel wat jongeren moeite
              om de grote hoeveelheden leerstof, die ze te verwerken krijgen, in
              te studeren.
            </p>
            <p>
              Om dit te verhelpen, leert de student(e) eerst de basisprincipes
              van studeren zodat hij/zij weet hoe te studeren en wat er moet
              gedaan worden om de leerstof te kunnen begrijpen.
            </p>
            <p>
              Vervolgens helpen we de student(e) zijn/haar leerstof te
              organiseren zodat hij/zij een beter overzicht krijgt. Hij/zij
              leert de belangrijke gegevens uit de leerstof te halen door het
              verschil te leren tussen belangrijke en bijkomstige gegevens.
            </p>
            <p>
              Daarna leert hij/zij de stof samenvatten zodat alles makkelijker
              kan verwerkt worden. Op die manier wordt een goede studeerhouding
              aangekweekt en wordt paniek voorkomen vlak voor of tijdens de
              examenperiodes.
            </p>
          </template>
        </ActivityInfo>
      </div>
    </div>
  </section>
  <!--<NextButton linkToID="prices" />-->
</template>

<script>
import ParallaxPage from "./ParallaxPage.vue";
import ActivityInfo from "../extra/ActivityInfo.vue";

export default {
  name: "ActivitiesPage",
  extends: ParallaxPage,
  components: {
    ActivityInfo,
    // NextButton,
  },
};
</script>

<style lang="scss" scoped>
.activities-page {
  background-image: url("../../assets/images/background/activities.jpg");

  .accent {
    font-style: italic;
  }

  .content {
    text-align: center;

    h2 {
      color: white;
      margin-bottom: 40px;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 1.4em;

      img {
        width: 300px;

        &.info-image {
          width: 190px;
        }
      }
    }
  }
}
</style>