<template>
  <TheHeader :forMobile="true" />
  <TheHeader :forMobile="false" />
  <ThePages/>
</template>

<script>
import TheHeader from "./components/extra/TheHeader.vue";
import ThePages from "./components/pages/ThePages.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    ThePages,
  },
};
</script>

<style lang="scss">
@import "./scss/reset";

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900");
// TODO: implement this
//@import url("font-awesome.min.css");

body {
  font-family: "Source Sans Pro", sans-serif;
  //letter-spacing: 0.5px;
  color: #39454b;

  strong {
    font-weight: 600;
  }
}
</style>
