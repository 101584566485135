<template>
  <header :class="{'for-mobile': !!forMobile, 'for-desktop': !forMobile}">
    <a v-if="!!forMobile" class="hamburger" @click="toggleMenu"><i class="fas fa-bars"></i></a>
    <h1 @click="scrollTo('.intro-page')">Creatief Leren</h1>
    <nav :class="{'is-mobile': !!isMobile, 'is-mobile-menu-open': isMobileMenuOpen }">
      <div v-if="!!forMobile" class="nav-mobile-logo">
        <img src="../../assets/images/misc/prof-head.png" />
      </div>
      <div class="menu" :class="{'is-mobile': !!isMobile }">
        <div><a @click="scrollTo('.intro-page')">Home</a></div>
        <div><a @click="scrollTo('.who-page')">Wie zijn we?</a></div>
        <div><a @click="scrollTo('.how-page')">Hoe gaan we te werk?</a></div>
        <div><a @click="scrollTo('.activities-page')">Activiteiten</a></div>
        <div><a @click="scrollTo('.prices-page')">Tarieven</a></div>
        <div><a @click="scrollTo('.contact-page')">Contact</a></div>
        <div><a @click="scrollTo('.usefulLinks-page')">Nuttige links</a></div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  props: {forMobile: Boolean},
  data() {
    return {
      isMobileMenuOpen: false,
    }
  },
  methods: {
    scrollTo(identifier) {
      var headerOffset = this.forMobile ? 45 : 71;
      window.scroll({
        top: document.querySelector(identifier).offsetTop - headerOffset,
        left: 0,
        behavior: 'smooth'
      });
      this.isMobileMenuOpen = false;
    },
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #333;
  color: white;
  height: 72px;
  top: 0px;
  letter-spacing: 0.6px;

  &.for-mobile {
    display: none;
  }

  h1 {
    height: 3em;
    line-height: 3em;
    font: {
      weight: bold;
      size: 1.4em;
    }
    padding-left: 1em;
    letter-spacing: -1px;
    cursor: pointer;
  }
  nav {
    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      & > div {
        margin: 0 1em;
        font-size: 1.2em;

        a {
          cursor: pointer;
        }
      }
    }
  }

}

@media only screen and (max-width: 1200px) {
  header {
    height: 45px;

    &.for-desktop {
      display: none;
    }

    &.for-mobile {
      display: flex;
    }


    .hamburger {
      font-size: 35px;
      padding-top: 5px;
      cursor: pointer;
    }
    
    h1 {
      height: 45px;
      line-height: 45px;
      margin: auto;
      width: 200px;
      padding-left: 0;
      padding-right: 17px;
      text-align: center;
    }

    nav {
      display: none;
      background: #333;
      
      &.is-mobile-menu-open {
        display:block;
        position: absolute;
        top: 45px;
        padding-bottom: 15px;

        .nav-mobile-logo {
          display: block;
          width: 100px;
          margin: 10px auto;
          border-radius: 50%;
          border: 3px solid white;
          background-color: #666;
        }

        .menu {
          display: block;
          font-weight: bold;
          line-height: 3em;
          width: 300px;

          &>div {
            font-size: 1em;
            border-bottom: 1px solid white;

            &:last-of-type {
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>