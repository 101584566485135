<template>
  <section class="usefulLinks-page parallax">
    <div class="pop-in left">
      <div class="content">
        <h2>Nuttige links en Online woordenboeken</h2>
        <p class="link">
          <a href="http://www.muiswerk.nl/mowb" target="_blank">
            <span class="left"><img src="../../assets/images/links/muiswerk_alpha.png"></span>
            <span class="right">muiswerk.nl</span>
          </a>
        </p>
        <p class="link">
          <a href="http://www.woorden.org" target="_blank">
            <span class="left"><img src="../../assets/images/links/woorden_alpha.png"></span>
            <span class="right">woorden.org</span>
          </a>
        </p>
        <p class="link">
          <a href="http://www.mijnwoordenboek.nl" target="_blank">
            <span class="left"><img src="../../assets/images/links/mwb_alpha.png"></span>
            <span class="right">mijnwoordenboek.nl</span>
          </a>
        </p>
        <p class="link">
          <a href="http://www.vandale.nl" target="_blank">
            <span class="left"><img src="../../assets/images/links/van_dale_alpha.png"></span>
            <span class="right">vandale.nl</span>
          </a>
        </p>
        <p class="link">
          <a href="http://www.encyclo.nl" target="_blank">
            <span class="left"><img src="../../assets/images/links/encyclo_alpha.png"></span>
            <span class="right">encyclo.nl</span>
          </a>
        </p>
        <p class="link">
          <a href="http://www.woordenlijst.org">
            <span class="left"><img src="../../assets/images/links/taaluniversum_alpha.png"></span>
            <span class="right">Taaluniversum</span>
          </a>
        </p>
      </div>
    </div>
    <!--<NextButton linkToID="activities" />-->
  </section>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.usefulLinks-page {
  background-image: url("../../assets/images/background/usefulLinks.jpg");

  .link {
    span {
      display: inline-block;

      &.left {
        width: 250px;
      }
      &.right {
        color: #39454b;
      }
    }
  }
}
</style>