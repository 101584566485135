<template>
  <section class="prices-page parallax">
    <div class="pop-in left">
      <div class="content">
        <h2>Wat zijn onze tarieven?</h2>
        <div>
          <div>Bijlessen:</div>
          <div>30 euro/uur</div>
        </div>
        <div>
          <div>Spelling en Spraakkunst:</div>
          <div>30 euro/uur</div>
        </div>
        <div>
          <div>Aanpak van dyslexie en dyscalculie:</div>
          <div>30 euro/uur</div>
        </div>
        <div>
          <div>Studiecoaching:</div>
          <div>
            <div>Jongeren: 30 euro/uur</div>
            <div>Volwassenen: 35 euro/uur</div>
          </div>
        </div>
      </div>
    </div>
    <!--<NextButton linkToID="activities" />-->
  </section>
</template>

<script>
import ParallaxPage from "./ParallaxPage.vue";
// import NextButton from './NextButton.vue'
export default {
  name: "PricesPage",
  extends: ParallaxPage,
  components: {
    // NextButton,
  },
};
</script>

<style lang="scss" scoped>
.prices-page {
  background-image: url("../../assets/images/background/prices.jpg");
  display: flex;
  flex-direction: column;

  .content {
    &>div {
      display: flex;
      padding: 25px 0;
      border-bottom: solid #b7bfc3 1px;

      &:last-of-type {
        border-bottom: none;
      }

      &>div {
        width: 50%;

        &:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
}
</style>