<template>
  <IntroPage/>
  <WhoPage/>
  <HowPage/>
  <ActivitiesPage/>
  <PricesPage/>
  <ContactPage/>
  <UsefulLinks/>
</template>

<script>
import IntroPage from "./IntroPage.vue";
import WhoPage from "./WhoPage.vue";
import HowPage from "./HowPage.vue";
import ActivitiesPage from "./ActivitiesPage.vue";
import PricesPage from "./PricesPage.vue";
import ContactPage from "./ContactPage.vue";
import UsefulLinks from "./UsefulLinksPage.vue";

export default {
  name: "ThePages",
  components: {
    IntroPage,
    WhoPage,
    HowPage,
    ActivitiesPage,
    PricesPage,
    ContactPage,
    UsefulLinks,
  },
};
</script>

<style lang="scss" scoped>

</style>