<template>
  <section class="intro-page parallax">
    <div class="content">
      <h2>Creatief Leren</h2>
      <div>
        <p class="slogan">Wij gaan voor resultaten</p>
        <p class="subtitle">
          Creatief Leren heeft 20 jaar ervaring in het geven van bijlessen en in
          het begeleiden van studie.
        </p>
      </div>
      <div class="more-info">
        <a @click="scrollToWho" class="button next">Meer informatie</a>
      </div>
    </div>
  </section>
</template>

<script>
import ParallaxPage from "./ParallaxPage.vue";
export default {
  name: "IntroPage",
  extends: ParallaxPage,
  methods: {
    scrollToWho() {
      var headerOffset = 71;
      window.scroll({
        top: document.querySelector('.who-page').offsetTop - headerOffset,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.intro-page {
  background-image: url("../../assets/images/background/intro.jpg");
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    padding-bottom: 150px;
    text-align: center;
    h2 {
      letter-spacing: -4px;
      font: {
        size: 100px;
        weight: bold;
      }
      padding-bottom: 50px;
    }

    p {
      margin: {
        bottom: 25px;
      }
      &.slogan {
        font: {
          weight: bold;
        }
      }
    }
    .more-info {
      padding-top: 100px;

      a {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .content {
      h2 {
        font-size: 50px;
      }

      .subtitle {
        margin: 0 10px;
      }
    }
  }
}
</style>