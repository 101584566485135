<template>
  <section class="parallax">
    <slot></slot>
    <NextButton v-if="nextID" />
  </section>
</template>

<script>
export default {
  name: "ParallaxPage",
  props: {
    nextID: String,
  },
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss">
.parallax {
  /* Full height */
  min-height: calc(100vh - 72px);
  width: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
  @media only screen and (max-width: 1200px) {
    background-attachment: scroll;
  }

  font: {
    size: 24px;
  }
  line-height: 40px;

  h2 {
    font: {
      size: 52px;
      weight: bold;
    }
    padding-top: 50px;
    padding-bottom: 26px;
  }

  .pop-in {
    display: flex;
    align-items: center;
    flex-grow: 1;

    &.right {
      justify-content: end;
    }
    &.left {
      justify-content: start;
    }

    .content {
      margin: 70px 0;
      background: white;
      padding: 55px;
      width: 620px;
      border: solid 1px black;

      &.wide {
        width: 1040px;
      }

      h2 {
        padding-top: 26px;
      }
    }

  }
  @media only screen and (max-width: 1200px) {
    font-size: 18px;
    line-height: 28px;

    min-height: 100vh;

    h2 {
      font-size: 40px;
    }

    .pop-in {
      &.right, &.left {
        justify-content: center;
        padding: 0 15px;

        .content {
          padding: 30px;

          h2 {
            padding-top: 0px;
            line-height: 38px;
          }
        }
      }
    }
  }
}
</style>