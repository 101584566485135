<template>
  <div class="activity-link" @click="toggleShown">
    <slot name="link"></slot>
  </div>
  <div
    class="activity-info"
    :class="{ shown: isInfoShown }"
    @click="toggleShown"
  >
    <div class="info-content" @click.stop>
      <div class="close" @click="toggleShown"></div>
      <div class="image"><slot name="info-image"></slot></div>
      <h2><slot name="info-title"></slot></h2>
      <slot name="info-text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityInfo",
  data() {
    return {
      isInfoShown: false,
    };
  },
  methods: {
    toggleShown() {
      this.isInfoShown = !this.isInfoShown;
      /*
       * Prevents scrolling of the body when popup is open while still allowing scroll of popup if it's bigger than the screen.
       * A little hacky but otherwise the event needs to be passed all the way back to the body
       */
      if(this.isInfoShown) {
        document.body.style.overflow='hidden';
      } else {
        document.body.style.overflow='auto';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.activity-link {
  color: white;
  width: 400px;
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
}

.activity-info {
  display: none;

  &.shown {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    color: #39454b;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: scroll;
    font-size: 24px;

    .info-content {
      text-align: left;
      background-color: white;
      border: solid black 1px;
      margin: 25px auto;
      padding: 25px;
      max-width: 1000px;
      line-height: 1.6em;
      opacity: 1;

      h2 {
        color: #39454b;
        padding-top: 0;
        padding-bottom: 0.2em;
        line-height: 50px;
      }

      .image {
        float: left;
      }

      .close {
        &:after {
          display: block;
          content: "\00d7"; /* This will render the 'X' */
          font-size: 3em;
          float: right;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .activity-info.shown {
    font-size: 18px;

    .info-content {
      .image {
        float: none;
      }
    }
  }
}
</style>