<template>
  <section class="contact-page parallax">
    <div class="content">
      <h2>Contacteer ons</h2>
      <p>
        Langendijk 14<br />
        2580 Putte (Grasheide)<br />
        Tel: 015/26.24.24<br />
        GSM: 0477/68.90.50
      </p>
    </div>
  </section>
</template>

<script>
import ParallaxPage from "./ParallaxPage.vue";

export default {
  name: "ContactPage",
  extends: ParallaxPage,
  components: {
  },
  data() {
    return {};
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.contact-page {
  background-image: url("../../assets/images/background/contact.jpg");
  text-align: center;
  color: white;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
  }

  @media only screen and (max-width: 1200px) {
    .content {
      margin: 0 15px;
    }
  }
}
</style>